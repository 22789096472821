<template>
  <div class="vtour-chart" v-loading="loading">
    <div class="vtour-chart-title">{{vtourName}}</div>
    <div class="vtour-chart-con">
      <chart-eight
          :value="chartData08"
          ref="chartEight"
          style="width: 100%;height: 100%"
      ></chart-eight>
    </div>
  </div>
</template>

<script>
  import ChartEight from "../charts/chart08.vue";
  import Stomp from "stompjs";
  export default {
    components: {
      ChartEight
    },
    name: "chart",
    data() {
      return{
        loading:false,
        vtourName:'',
        chartData08: {},
        topic:null,
        client: null,
        list:[],
        timer:null
      }
    },
    mounted() {
      this.vtourName = this.$route.query.name
      this.getLineData(this.$route.query.name)
    },
    watch: {
      topic: {
        handler(val) {
          this.disconnect();
          this.startListen();
        },
        deep: true,
        immediate: true,
      },
    },
    methods:{
      getLineData(name){
        this.loading = true
        let self = this
        self.$http.get('/common/lineData',{params:{sensorName:name}}).then(res => {
          if (res.success){
            self.topic = res.data.topic
            localStorage.setItem("oldHotspot",name);
            self.$nextTick(() => {
              self.$refs["chartEight"].loadEcharts(res.data.chart);
            })
          } else {
            this.$message.error(res.msg)
            this.loading = false
          }
        })
      },
      changeLoad() {
        this.loading = false;
      },
      //开启监听mq
      startListen(){
        var timestamp = new Date().getTime();
        var clientid = timestamp + Math.floor(Math.random() * 10000);
        var headers = {
          login: this.$MQ_USER,
          passcode: this.$MQ_PWD,
          "client-id": clientid
        };
        if (!this.client || !this.client.connected) {
          this.client = Stomp.client(this.$MQURL);
        }
        this.client.connect(
            headers,
            this.onConnected,
            this.onFailed
        );
      },
      onConnected: function(frame) {
        var topic = "/topic/"+this.topic;
        // 订阅频道
        this.client.subscribe(topic, this.responseCallback, this.onFailed);
        this.client.debug = null;
      },
      onFailed: function(frame) {
        console.log("Failed: " + frame);
      },
      responseCallback: function(frame) {
        var that = this;
        let body = JSON.parse(frame.body);
        body.forEach(item => {
          that.list.push(item)
        })
        this.$refs['chartEight'].getMqData(body);
      },
      disconnect: function() {
        if(this.client){
          let that = this
          that.client.disconnect(function(e) {
            that.client = null
          });
        }
      },
    },
    beforeDestroy() {
      this.disconnect()
      clearInterval(this.timer);
      this.timer = null;
    }
  }
</script>

<style scoped>
  .vtour-chart {
    width: 500px;
    height: 200px;
    background: url('../../../../../assets/images/single/bg_vtour.png');
    background-size: 100% 100%;
  }
  .vtour-close {
    width: 25px;
    height: 25px;
    /*background: #333;*/
    font-size: 24px;
    line-height: 25px;
    text-align: center;
    color: #efefef;
    position: absolute;
    right: 0;
    top: 0;
    transition: all ease .38s;
  }
  .vtour-close:hover {
    cursor: pointer;
    transform: rotate(90deg);
  }
  .vtour-chart-title{
    width: 100%;
    height: 40px;
    line-height: 40px;
    text-align: center;
    color: #fff;
  }
  .vtour-chart-con{
    width: 100%;
    height: calc(100% - 40px);
  }
</style>